import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PK_KEY);
  }

  return stripePromise;
};
