import { functions } from '@/config/firebase.config';

export const createCheckoutSession = (id) => {
  return functions
    .httpsCallable('createCheckoutSession')({
      id,
    })
    .then((res) => {
      return res.data.data;
    });
};
