<template>
  <div :id="$style.summary" :class="['main-wrap']">
    <!-- main -->
    <div>
      <div class="main-section">
        <div class="container h-100 small">
          <header :class="$style.header">
            <div :class="['top', $style.radialProgressContainer]">
              <div class="ring">
                <svg
                  :class="['radial-progress', $style.radialProgress]"
                  :data-percentage="publisherData.matchedPercentage"
                  viewBox="0 0 80 80"
                >
                  <circle class="incomplete" cx="40" cy="40" r="35"></circle>
                  <circle
                    class="complete"
                    cx="40"
                    cy="40"
                    r="35"
                    :style="completeCircleStyle"
                  ></circle>
                </svg>
              </div>
              <h2 :class="$style.percentageFoundText">
                {{ Math.round(publisherData.matchedPercentage) }}% found
              </h2>
            </div>
            <router-link to="/" class="logo">
              <img :src="require('@/assets/img/logo.png')" alt="" />
            </router-link>
          </header>
          <section :class="$style.tableContainer">
            <h3 :class="$style.previewResultsText">
              Here’s a preview of your results
            </h3>
            <table :class="$style.table">
              <thead :class="$style.tableHeader">
                <tr>
                  <th>Track</th>
                  <th>Performers</th>
                  <th>ISRC</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="{
                    id,
                    title,
                    performers,
                    isrc,
                  } of publisherData.previewPlaylists"
                  :key="id"
                >
                  <td>
                    {{ title }}
                  </td>
                  <td>{{ performers }}</td>
                  <td>{{ isrc }}</td>
                </tr>
              </tbody>
            </table>
          </section>
          <div class="data-show">
            <button
              :disabled="initCheckoutStatusPending"
              @click.prevent="initCheckout"
              class="button default-button"
            >
              {{
                initCheckoutStatusPending
                  ? "Processing"
                  : "Buy and Download these results"
              }}
            </button>
          </div>
          <Footer />
        </div>
      </div>
    </div>
    <!-- main end -->
  </div>
</template>

<script>
import { publisherDataComputedState } from "@/views/apps/services/publisherDataService";
import { getStripe } from "@/services/stripeService";
import { API_STATUS } from "@/constants/apiStatus";
import { createCheckoutSession } from "@/api/stripeApi";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import Footer from "../Footer.vue";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  components: {
    Footer,
  },
  data() {
    return {
      initCheckoutStatus: IDLE,
    };
  },
  computed: {
    ...publisherDataComputedState,
    ...apiStatusComputedFactory("initCheckoutStatus"),
    completeCircleStyle() {
      const radius = 35;
      const circumference = 2 * Math.PI * radius;
      const strokeDashOffset =
        circumference -
        (this.publisherData.matchedPercentage * circumference) / 100;

      return {
        "stroke-dashoffset": strokeDashOffset,
      };
    },
  },
  methods: {
    async initCheckout() {
      try {
        const stripe = await this.stripePromise;
        this.initCheckoutStatus = PENDING;
        const response = await createCheckoutSession(this.publisherData.id);
        if (!response)
          throw new Error(
            "There was a problem while initialising the checkout process."
          );
        console.log("response", response);
        const result = await stripe.redirectToCheckout({
          sessionId: response.session.id,
        });

        if (result.error) {
          throw result.error;
        }
        this.initCheckoutStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.initCheckoutStatus = ERROR;
      }
    },
    loadStripe() {
      this.stripePromise = new Promise((resolve) => {
        requestAnimationFrame(() => {
          resolve(getStripe());
        });
      });
    },
  },
  mounted() {
    this.loadStripe();
  },
  created() {
    if (!this.publisherData.matchedCodesCount) {
      this.$router.push("/");
      return;
    }
  },
};
</script>
<style src="../../css/boostrap.min.css"></style>
<style src="../../css/styles.css"></style>
<style lang="scss" module>
#summary {
  .matchedCount {
    fill: #373a3c !important;
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
  }

  .radialProgressContainer {
    display: flex;
    align-items: center;
  }

  svg.radialProgress {
    max-width: 85px;
  }

  .percentageFoundText {
    font-size: 1.8rem;
    font-weight: 500;
    font-family: $primary-font;
  }

  .tableContainer {
    width: 100%;
    flex-grow: 1;
  }

  .previewResultsText {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 500;
    color: $secondary-text;
    margin-bottom: 1.25rem;
  }

  .table {
    position: relative;
    width: 100%;
    td {
      color: $primary-text;
      padding: 0.25rem 0.5rem;
      line-height: 2;
      font-size: 1rem;
      letter-spacing: 0.5px;
    }
  }

  .tableHeader {
    position: sticky;
    top: 0;
    background-color: #f8f9fb;
    padding: 0 1rem;

    th {
      padding: 0.5rem;
      color: $primary-text;
      display: table-cell !important;
      font-size: 1rem;
      letter-spacing: 0.5px;
    }
  }
}
</style>
<style scoped>
.container {
  flex-grow: 1;
}

.data-show {
  margin: 0 0 3rem 0;
}
.main-section {
  flex-direction: column;
}
</style>